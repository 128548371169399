<template>
  <section>
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card-actions
          ref="filters"
          title="Filtros"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <validation-observer ref="rulesAssignTo">
            <b-row>
              <b-col cols="3">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label="De"
                    label-for="created_at_from"
                  >
                    <b-input-group>
                      <cleave
                        id="created_at_from"
                        v-model="runtimeFilters.created_at_from"
                        type="text"
                        class="form-control"
                        placeholder="DD/MM/YYYY"
                        autocomplete="off"
                        :raw="false"
                        show-decade-nav
                        :options="maskDate"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="created_at_from_dp"
                          show-decade-nav
                          button-only
                          button-variant="outline-primary"
                          right
                          size="sm"
                          :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
                          locale="pt-BR"
                          aria-controls="example-input"
                          @context="(ctx) => { runtimeFilters.created_at_from = (ctx.selectedDate) ? ctx.selectedFormatted : '' }"
                        />
                      </b-input-group-append>

                      <b-input-group-append v-if="runtimeFilters.created_at_from.length > 0">
                        <b-button
                          variant="outline-primary"
                          size="sm"
                          @click="runtimeFilters.created_at_from = ''"
                        >
                          <feather-icon icon="XIcon" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col cols="3">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label="Até"
                    label-for="created_at_to"
                  >
                    <b-input-group>
                      <cleave
                        id="created_at_to"
                        v-model="runtimeFilters.created_at_to"
                        type="text"
                        class="form-control"
                        placeholder="DD/MM/YYYY"
                        autocomplete="off"
                        :raw="false"
                        show-decade-nav
                        :options="maskDate"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="created_at_to_dp"
                          show-decade-nav
                          button-only
                          button-variant="outline-primary"
                          right
                          size="sm"
                          :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
                          locale="pt-BR"
                          aria-controls="example-input"
                          @context="(ctx) => { runtimeFilters.created_at_to = (ctx.selectedDate) ? ctx.selectedFormatted : '' }"
                        />
                      </b-input-group-append>

                      <b-input-group-append v-if="runtimeFilters.created_at_to.length > 0">
                        <b-button
                          variant="outline-primary"
                          size="sm"
                          @click="runtimeFilters.created_at_to = ''"
                        >
                          <feather-icon icon="XIcon" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
          </validation-observer>

          <b-row class="text-right">
            <b-col
              cols="12"
              class="form-buttons"
            >
              <b-form-group
                label=""
                label-for="btn-buscar"
                style="margin-top: 20px"
              >
                <b-button
                  id="btn-buscar"
                  variant="primary"
                  @click="getSmarties()"
                >
                  Buscar
                </b-button>

                <b-button
                  id="btn-limpar"
                  variant="outline-secondary"
                  @click="reset()"
                >
                  Limpar
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card v-if="isSubmited">
          <b-row>
            <b-col>

              <b-table
                :busy="isLoading"
                fixed
                :fields="result_fields"
                :items="results"
                sort-icon-left
                no-local-sorting
                :sort-by.sync="filtersValues.sortBy"
                :sort-desc.sync="filtersValues.sortDesc"
                @sort-changed="sortingChanged"
              >
                <template #table-busy>
                  <div class="text-center table-spinner">
                    <b-spinner label="Loading..." />
                    <strong>Carregando...</strong>
                  </div>
                </template>

                <!-- eslint-disable vue/no-v-html -->
                <template #cell(presentation)="row">
                  <label>Nome:</label> <span v-html="row.item.name" /><br>
                  <label>Empresa:</label> <span v-html="row.item.company_name" /> (<span v-html="row.item.company_name_filtered" />)<br>
                  <label>Email:</label> {{ row.item ? row.item.email : '' }}<br>
                  <label>Marcas:</label> <span v-html="row.item.company_brands" /><br>
                  <label>Apresentação:</label><br>
                  <p v-html="row.item.presentation" />
                </template>
                <!-- eslint-enable vue/no-v-html -->

                <template #cell(actions)="row">
                  <div class="text-nowrap text-center">
                    <b-button
                      v-if="$can('read', 'Users')"
                      variant="flat-primary"
                      title="Abrir perfil"
                      target="_blank"
                      :to="{ name: 'cms-user-form-update', params: { id: row.item.id } }"
                    >
                      <feather-icon
                        icon="UserIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </b-button>

                    <b-button
                      v-if="$can('read', 'Users')"
                      variant="flat-primary"
                      title="Retirar da lista"
                      @click="remove(row.item.id)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
            </b-col>

            <b-col cols="6">
              <!-- pagination nav -->
              <b-pagination-nav
                v-model="currentPage"
                :number-of-pages="pages.last_page"
                base-url="#"
                align="end"
              />
            </b-col>
          </b-row>

        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BBreadcrumb,
  BFormDatepicker,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BSpinner,
  BPaginationNav,
  BTable,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import { maskDate } from '@/utils/masks'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  months,
} from '@/utils/options'
import { regex } from '@validations'
import UserService from '@/services/userService'
import toast from '@/mixins/toast'

export default {
  components: {
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BBreadcrumb,
    BButton,
    BCard,
    BCardActions,
    BCol,
    BFormGroup,
    BRow,
    BSpinner,
    BTable,
    BPaginationNav,
    Cleave,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [
    toast,
  ],
  data() {
    return {
      regex,

      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Relatórios',
          active: true,
        },
        {
          text: 'Busca por Espertinhos',
          active: true,
        },
      ],
      created_at_from_dp: '',
      created_at_to_dp: '',
      filtersValues: {
        sortBy: 'id',
        sortDesc: true,
      },
      runtimeFilters: {
        created_at_from: '',
        created_at_to: '',
      },
      months,
      isLoading: true,
      isSubmited: false,
      goalToDeleteWhenEditing: null,
      maskDate,
      pages: 1,
      currentPage: 1,
      results: [],
      result_fields: [],
      userService: null,
    }
  },
  computed: {
  },
  watch: {
    currentPage() {
      this.getSmarties()
    },
  },
  created() {
    this.userService = new UserService()
    this.result_fields = [
      { key: 'id', sortable: false, thStyle: 'width: 100px' },
      { key: 'presentation', sortable: false },
      { key: 'actions', sortable: true, thStyle: 'width: 150px' },
    ]
  },
  methods: {
    reset() {
      this.runtimeFilters = Object.fromEntries(Object.entries(this.runtimeFilters).map(item => {
        // eslint-disable-next-line no-param-reassign
        item[1] = ''
        return item
      }))
    },
    transformDate(date) {
      if (date.length === 10) {
        const from = date.split('/')
        return `${from[2]}-${from[1]}-${from[0]}`
      }
      return ''
    },
    bold(str, companyNameFiltered) {
      if (str == null) {
        return ''
      }

      let bolded = str.replace(/(www)/gi, '<span class="text-danger">$1</span>')
        .replace(/(http)/gi, '<span class="text-danger">$1</span>')
        .replace(/(@)/gi, '<span class="text-danger">$1</span>')
        .replace(/(.com.br)/gi, '<span class="text-danger">$1</span>')
        .replace(/([0-9]{5})/gi, '<span class="text-danger">$1</span>')
        .replace(/([0-9-]{8})/gi, '<span class="text-danger">$1</span>')

      if (companyNameFiltered) {
        bolded = bolded.replace(new RegExp(`(${companyNameFiltered})`, 'gi'), '<span class="text-danger">$1</span>')
      }

      return bolded
    },
    getSmarties() {
      this.$refs.rulesAssignTo.validate().then(success => {
        if (success) {
          this.isSubmited = true
          this.isLoading = true

          const dateFrom = this.runtimeFilters.created_at_from ? this.transformDate(this.runtimeFilters.created_at_from) : ''
          const dateTo = this.runtimeFilters.created_at_to ? this.transformDate(this.runtimeFilters.created_at_to) : ''

          this.userService.listSmarties(dateFrom, dateTo, this.currentPage)
            .then(response => {
              this.results = response.data.data.users.data.map(m => ({
                ...m,
                name: this.bold(m.name),
                company_brands: m.company_brands ? this.bold(JSON.parse(m.company_brands).join(', ')) : '',
                presentation: this.bold(m.presentation, m.company_name_filtered),
              }))

              this.pages = { ...response.data.data.users, data: null }
            })
            .catch(() => {
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    remove(id) {
      this.$swal({
        title: 'Remover da lista?',
        text: 'Marcar o usuário como falso possitivo até a próxima alteração do perfil?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.userService.disableSmarties(id).then(response => {
            this.isRemoving = false
            if (response.status === 200) {
              this.results = this.results.filter(filter => filter.id !== id)
              this.toastSuccess('Usuário removido da listagem de espertinhos!')
            }
          })
        }
      }).catch(error => {
        this.toastDanger('Erro!', error.response.data.message)
      })
    },
    sortingChanged(ctx) {
      if (ctx.sortBy !== '') {
        this.filtersValues.sortBy = ctx.sortBy
        this.filtersValues.sortDesc = ctx.sortDesc
        this.getSmarties()
      }
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .text-danger{
      font-weight: bold;
  }
</style>
